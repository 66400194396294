let config = {
	request: {
		// #ifdef H5
		baseUrl: window.location.protocol + "//" + window.location.host+"/api",
		// #endif
		// #ifdef MP || APP-PLUS || APP-PLUS
		baseUrl: "https://b2cv3.a3-mall.com/api",
		// #endif
		header: {
			'content-type': 'application/json'
		},
		timeout: 60000,
		dataType: "json",
		responseType: "text"
	}
}

if(process.env.NODE_ENV === "development"){
	config.request.baseUrl = "http://a3-shop.top:8888/api";
	// config.request.baseUrl = "https://v3.a3-mall.com/api";
}

export default config;