import { deepMerge } from "../libs/utils";
import { browser,isEmpty,inArray } from "../libs/check";
import { navigateTo } from "../libs/navigate";
import { useUserStore } from "@/store/user";

export const domain = ()=>{
	let url = uni.$config.get("request.baseUrl");
	return url.substr(-1, 1) != '/' ? url + "/" : url;
}

export const getDomain = ()=>{
	return domain().replace("/api/","");
}

export const request = (url="",data={},options={},method="GET")=>{
	return new Promise((resolve,reject)=>{
		const {type,isWechat} = browser();
		const config = uni.$config.get("request");
		const token  = uni.$storage.get("token","");
		
		if(options.header == undefined) {
			options.header = {};
		}
		
		if(!isEmpty(token)){
			options.header["Authorization"] = "Bearer " + token;
		}else{
			options.header["Authorization"] = "";
		}
		
		if(options.isLoading == undefined){
			options.isLoading = true;
		}
		
		options.header["Client-Type"] = isWechat ? "wechat" : type;
		let header = deepMerge(config.header,options.header);
		let params = deepMerge({
		    url: domain() + url,
		    data: data,
		    header: header,
			method: method,
			timeout: config.timeout || 60000,
			dataType: config.dataType || "json",
			// #ifndef MP-ALIPAY
			responseType: config.responseType || "text",
			// #endif
			success: (res)=>{
				if(inArray(method,["POST"]) && options.isLoading) uni.hideLoading();
				if(res.errMsg != 'request:ok'){
					reject(res.errMsg,res.statusCode);
				}else{
					const { code,data } = res.data;
					if(code == 401){
						const userStore = useUserStore();
						userStore.logout();
						navigateTo("pages/user/login");
					}else if(code == 404){
						navigateTo("pages/public/empty");
					}else{
						resolve(res.data,{ header: res.header, status: res.statusCode });
					}
				}
			},
			fail: (error)=>{
				reject(error.errMsg,500);
			}
		},options);
		
		if(inArray(method,["POST"]) && options.isLoading){
			uni.showLoading({ title: "加载中...", mask: false });
		}
		
		uni.request(params);
	});
}

export const get = (url="",data={},options={})=>{
	return request(url,data,options,"GET");
}

export const post = (url="",data={},options={})=>{
	return request(url,data,options,"POST");
}

export const put = (url="",data={},options={})=>{
	return request(url,data,options,"PUT");
}

export const del = (url="",data={},options={})=>{
	return request(url,data,options,"DELETE");
}

export const connect = (url="",data={},options={})=>{
	return request(url,data,options,"CONNECT");
}

export const head = (url="",data={},options={})=>{
	return request(url,data,options,"HEAD");
}

export const options = (url="",data={},options={})=>{
	return request(url,data,options,"OPTIONS");
}

export const trace = (url="",data={},options={})=>{
	return request(url,data,options,"TRACE");
}

export const download = (url="",options={})=>{
	return new Promise((resolve,reject)=>{
		let params = deepMerge({
			success: (res)=>{},
			fail: (error)={}
		},options);
		params.url = domain() + url;
		uni.downloadFile(params);
	});
}

/*

const {type,isWechat} = browser();
		const config = uni.$config.get("request");
		const token  = uni.$storage.get("token","");
		
		if(isEmpty(options)) {
			options.header = {};
		}
		
		if(!isEmpty(token)){
			options.header["Authorization"] = "Bearer " + token;
		}
		
		options.header["Client-Type"] = isWechat ? "wechat" : type;
		let header = deepMerge(config.header,options.header);

*/
export const upload = (url,data=null,options={})=>{
	return new Promise((resolve,reject)=>{
		if(isEmpty(data)){
			reject("请选择需要上传的内容");
			return false;
		}
		
		const {type,isWechat} = browser();
		const config 		  = uni.$config.get("request");
		const token  	      = uni.$storage.get("token","");
		
		if(options.header == undefined) {
			options.header = {};
		}
		
		if(!isEmpty(token)){
			options.header["Authorization"] = "Bearer " + token;
		}
		
		options.header["Client-Type"] = isWechat ? "wechat" : type;
		let params = deepMerge({
			header: options.header,
			formData: {},
			success: (res)=>{
				const data = JSON.parse(res.data);
				resolve(data);
			},
			fail: (error)=>{
				reject(error);
			}
		},options);
		params.url 	 	= domain() + url;
		params.filePath = data;
		uni.uploadFile(params);
	});
}

export default {
	domain,
	getDomain,
	request,
	get,
	post,
	put,
	del,
	connect,
	head,
	options,
	trace,
	download,
	upload
}